import { render } from "./MyTeams.vue?vue&type=template&id=2bb9e5f0&scoped=true"
import script from "./MyTeams.vue?vue&type=script&lang=js"
export * from "./MyTeams.vue?vue&type=script&lang=js"

import "./MyTeams.vue?vue&type=style&index=0&id=2bb9e5f0&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-2bb9e5f0"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QAvatar,QItemLabel,QIcon});qInstall(script, 'directives', {Ripple});
