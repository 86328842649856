<template>
  <h5 v-if="theuser" class="q-mt-xl q-ma-md text-center">Name: <a :href="WEBROOT + '/' + 'shop/' + theuser.id" >{{ theuser.nickname }}</a></h5>
  <h5 class="q-ma-md text-center"> Total: {{total}}</h5>
  <!-- <h5 v-if="theuser" class="text-center">User Level: {{ theuser.ulevel }}</h5>  -->
  <div
    class="
      q-pa-md q-gutter-md
      fit
      row
      wrap
      justify-center
      items-start
      content-start
    "
  >
    <!-- Team List -->
    <q-list class="my-list" bordered>
      <q-item
        :to="'/team/' + k.id"
        v-for="k in myteams"
        :key="k.id"
        clickable
        v-ripple
      >
        <q-item-section avatar>
          <q-avatar color="primary" text-color="white">
            <!-- {{ k.nickname.letter }} -->
            <img v-if="k.avatar" :src="k.avatar" />
            <span v-else-if="k.nickname">{{ k.nickname.substring(0, 1) }}</span>
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <!-- <a :to="k.utype == 'seller' ? '/shop/' + k.id : '/profile/' + k.id"> -->
          <q-item-label>
            <span v-if="k.nickname" class="text-blue">
              {{ k.nickname }}
            </span>
            <span v-else class="text-red"> NO NAME </span>
          </q-item-label>
          <q-item-label caption lines="1">{{ k.bio }}</q-item-label>
          <!-- </a> -->
        </q-item-section>
        <q-item-section>
                    <q-item-label class="text-blue">
                      {{pdate(k.created_at)}}
                    </q-item-label>
        </q-item-section>
        <q-item-section side>
          <!-- <a :href="'/team/' + k.id"> -->
          <q-icon name="chevron_right" size="lg" color="green" />
          <!-- </a> -->
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<style lang="sass" scoped>

.my-list
  width: 100%
  max-width: 800px
</style>

<script>
// import router from "@/router";
import { useRoute } from "vue-router";
import { ref, onBeforeMount } from "vue";
import { supabase as s } from "../supabase";
import {WEBROOT} from "../web3"
export default {
  name: "MyTokens",
  setup() {
    const myteams = ref([]);
    const theuser = ref(null);
    const total = ref(0);
    // const user = s.auth.user();
    const r = useRoute();
    console.log("route:", r);
    const theuserid = r.params.uid;
    console.log(theuserid);

    onBeforeMount(async () => {
      let thedata = await s.from("profiles").select("*").eq("id", theuserid).order('created_at',{ ascending: false });
      if (error) console.log(error);
      console.log("theuser:", thedata["data"]);
      theuser.value = thedata["data"][0];
      console.log(theuser.value);

      let { data: teams, error,count} = await s
        .from("profiles")
        .select("*",{ count: 'exact' })
        .eq("rid", theuser.value.id)
        .order("created_at", { ascending: false }).limit(30);

      console.log("members:",teams);
      myteams.value = teams;
      total.value = count;
      console.log("total:",count)
    });
    return {
      total,
      theuser,
      myteams,
      WEBROOT
    };
  },
  methods: {
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
  },
};
</script>